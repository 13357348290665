<template>
  <div class="add-state-announcement add-template">
    <g-background>
      <page-header>
        <h1>{{ translations.tcEditAnnouncement }}</h1>
      </page-header>

      <page-body>
        <div class="page-navigation">
          <div class="nav-item nav-item-1 active">
            <div class="icon">
              <geo-dis />
            </div>
            <div class="text">{{ translations.tcGeographicDistribution }}</div>
          </div>
          <div class="sep active"></div>
          <div class="nav-item nav-item-2 active">
            <div class="icon">
              <audience-icon />
            </div>
            <div class="text">{{ translations.tcAudience }}</div>
          </div>
          <div class="sep active"></div>
          <div class="nav-item nav-item-3 active">
            <div class="icon">
              <message-icon />
            </div>
            <div class="text">{{ translations.tcEnterMessage }}</div>
          </div>
          <div class="sep active"></div>
          <div class="nav-item nav-item-4 active">
            <div class="icon">
              <review-icon />
            </div>
            <div class="text">{{ translations.tcReviewAndEdit }}</div>
          </div>
        </div>
      </page-body>
      <hr />
      <div v-if="!!announcementForm.expires">
        <div v-if="!isRegionalCommitteeAnnouncement">
          <AnnouncementReview
            :audienceDistribution="audienceDistribution"
            :state_country_object="state_country_object"
            :selectedStateRegions="selectedStateRegions"
            :selectedCommittees="selectedCommittees"
            :selectedCommitteeCountries="selectedCommitteeCountries"
            :d_recipients="d_recipients"
            :cmt_rgn_both="cmt_rgn_both"
            :includeOfficers="announcementForm.includeElected"
          ></AnnouncementReview>
        </div>
        <div v-else>
          <AnnouncementReview
            :audienceDistribution="audienceDistribution"
            :selectedCommittees="regionalCommitteeCountries"
            :selectedUserCommittees="selectedCommittees"
            :cmt_rgn_both="cmt_rgn_both"
            :includeOfficers="announcementForm.includeElected"
          ></AnnouncementReview>
        </div>
      </div>
      <section class="form-buttons">
        <b-button variant="tertiary" @click="handleEditClick"> {{ translations.tcEdit }} </b-button>&nbsp;
        <b-button variant="tertiary" @click="handleDeleteRequest"> {{ translations.tcDelete }} </b-button>&nbsp;
        <b-button variant="tertiary" @click="handleCancelClick">
          {{ translations.tcCancel }}
        </b-button>
      </section>
    </g-background>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import AnnouncementReview from '@/components/AnnouncementReview.vue'
import audienceIcon from '@/assets/svgs/audience-icon.vue'
import gBackground from '@/components/gBackground.vue'
import GeoDis from '@/assets/svgs/geographic-distribution-icon.vue'
import messageIcon from '@/assets/svgs/message-icon.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import reviewIcon from '@/assets/svgs/review-icon.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'edit-announcement',
  mixins: [translationMixin],
  data() {
    return {
      d_recipients: null,
      filteredItems: {
        type: '',
        items: [],
      },
      selectedCommittees: {},
      state_country_object: {
        title: `Edit Announcement`,
        include: 'Include National Leaders',
        full: 'Full State',
        selected: 'Selected Areas',
        leadership: 'State Leadership Only',
      },
      translations: {},
    }
  },
  async created() {
    await Promise.all([this.pageLoad()]).then(() => {
      this.setTranslations()
    })
  },
  computed: {
    ...mapGetters({
      announcementForm: 'announcements/announcementForm',
      announcementsForEdit: 'announcements/announcementsForEdit',
      campAllMembers: 'announcements/stateBothMembers',
      campAllLeaders: 'announcements/campAllLeaders',
      committeeCountries: 'announcements/committeeCountries',
      committeeIndividuals: 'announcements/committeeIndividuals',
      committeesList: 'announcements/committeesList',
      committeesListWithAllSelected: 'announcements/committeesListWithAllSelected',
      committeesListWithSomeSelected: 'announcements/committeesListWithSomeSelected',
      prefCulture: 'user/userPreferredCulture',
      selectedCommitteeCountries: 'announcements/selectedCommitteeCountries',
      selectedCommitteeMembers: 'announcements/selectedCommitteeMembers',
      selectedStateRegions: 'announcements/selectedStateRegions',
      selectedUserCommittees: 'announcements/selectedUserCommittees',
      stateAllLeaders: 'announcements/stateAllLeaders',
      stateGideonManagement: 'announcements/stateGideonManagement',
      stateAuxiliaryManagement: 'announcements/stateAuxiliaryManagement',
      stateBothManagement: 'announcements/stateBothManagement',
    }),
    cmt_rgn_both() {
      let ad =
        !!this.audienceDistribution && !!this.audienceDistribution[0] && !!this.audienceDistribution[0].areas ? 1 : 0
      let scc = !!this.selectedCommitteeCountries && !!this.selectedCommitteeCountries.areas ? 1 : 0
      return ad + scc
    },
    audienceDistribution() {
      let aud_dist = []
      if (
        ['AreasCamp', 'RCommitteeCamp', 'ZonesCamp', 'FullCamp'].filter((ad) => this.announcementForm.type.includes(ad))
          .length > 0
      ) {
        aud_dist = [...aud_dist, ...this.campAllLeaders]
      }
      if (
        ['AreasMembers', 'FullMembers', 'ZonesMembers', 'CampCamp', 'RCommitteeMember'].filter((ad) =>
          this.announcementForm.type.includes(ad)
        ).length > 0
      ) {
        aud_dist = [...aud_dist, ...this.campAllMembers]
      }
      if (this.announcementForm.type.includes('FullState')) {
        aud_dist = [...aud_dist, ...this.stateAllLeaders]
      }
      if (['CommitteesMembers'].filter((ad) => this.announcementForm.type.includes(ad)).length > 0) {
        aud_dist = [...aud_dist, ...this.committeesListWithSomeSelected(['aud', 'mbr'])]
        this.state_country_object = {}
        return aud_dist
      }
      if (['RCommitteeManagement'].filter((ad) => this.announcementForm.type.includes(ad)).length > 0) {
        switch (this.announcementForm.msg_member_type) {
          case 'g':
            aud_dist = [...aud_dist, ...this.stateGideonManagement]
            break
          case 'a':
            aud_dist = [...aud_dist, ...this.stateAuxiliaryManagement]
            break
          case 'b':
            aud_dist = [...aud_dist, ...this.stateBothManagement]
            break
        }
      }
      if (['RCommitteeRCMember'].filter((ad) => this.announcementForm.type.includes(ad)).length > 0) {
        this.selectedCommittees = {
          areas: this.announcementForm.mbr_array.map((area) => {
            return {
              key: area.key,
              name: area.name,
              text: area.name,
              type: area.type,
            }
          }),
        }
      }
      if (['AreasManagement', 'ZonesManagement'].filter((ad) => this.announcementForm.type.includes(ad)).length > 0) {
        aud_dist = [...aud_dist, ...this.committeesList]
      }
      if (['AreasCamp', 'AreasMembers'].filter((ad) => this.announcementForm.type.includes(ad)).length > 0) {
        this.setStateRegionsForEdit()
      }
      return aud_dist.filter((af) => {
        return (
          af.areas.filter((aff) => {
            return aff.selected === true
          }).length > 0
        )
      })
    },
    isRegionalCommitteeAnnouncement() {
      if (
        ['RCommitteeMember', 'RCommitteeCamp', 'RCommitteeManagement', 'RCommitteeRCMember'].filter((ad) =>
          this.announcementForm.type.includes(ad)
        ).length > 0
      ) {
        return true
      }
      return false
    },
    regionalCommitteeCountries() {
      let return_dist = []
      if (this.isRegionalCommitteeAnnouncement && this.showRegionalCommittee) {
        return_dist = {
          areas: this.announcementForm.geo_array.map((area) => {
            return {
              text: area.name,
            }
          }),
        }
      } else {
        return_dist = {
          areas: [],
        }
      }
      return return_dist
    },
    showRegionalCommittee() {
      return (
        ['RCommitteeMember', 'RCommitteeCamp', 'RCommitteeManagement'].filter((ad) =>
          this.announcementForm.type.includes(ad)
        ).length > 0
      )
    },
  },
  methods: {
    ...mapActions({
      clearSelectedCommitteeCountries: 'announcements/clearSelectedCommitteeCountries',
      deleteAnnouncementLocally: 'announcements/deleteAnnouncementLocally',
      deleteAnnouncementMessage: 'announcements/deleteAnnouncementMessage',
      setAudienceForEdit: 'announcements/setAudienceForEdit',
      setLoadingStatus: 'menu/setLoadingStatus',
      setMessagePackageForDelete: 'announcements/setMessagePackageForDelete',
      setStateRegionsForEdit: 'announcements/setStateRegionsForEdit',
    }),
    async pageLoad() {
      try {
        await Promise.all([
          this.setLoadingStatus(true),
          await this.getViewTranslations(),
          await this.setFullSelected(),
          await this.setAudienceForEdit(),
          await this.removeCountries(),
        ])
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async setFullSelected() {
      this.d_recipients =
        this.announcementForm.type.filter((sf) => {
          return sf.includes('Areas') || sf.includes('Zones')
        }).length > 0
          ? 'Selected'
          : 'Full'
      if (
        this.announcementForm.type.filter((sf) => {
          return sf === 'FullState'
        }).length > 0
      ) {
        this.d_recipients = 'Full'
      } else if (
        this.announcementForm.type.filter((sf) => {
          return sf === 'CampCamp'
        }).length > 0
      ) {
        this.d_recipients = 'Camp'
      }
      if (this.announcementForm.type.includes('CommitteesMembers')) {
        this.d_recipients = 'Committee'
        this.selectedCommittees = this.selectedUserCommittees
      }
      // Below block makes assumption that length of announcementForm.type will always be 1.
      if (this.announcementForm.type[0].includes('RCommittee')) {
        this.d_recipients = 'RCommittee'
      }
    },
    async handleDeleteRequest() {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        text: this.translations.tcAnnouncementWillBeDeletedOkay,
        confirmButtonText: this.translations.tcOk || 'Ok',
        cancelButtonText: this.translations.tcCancel || 'Cancel',
      }).then((result) => {
        if (result.value) {
          this.handleDelete(this.announcementForm.msg_message_id)
        }
      })
    },
    async handleDelete() {
      try {
        let del_result = true
        await Promise.all([
          this.setLoadingStatus(true),
          await this.setMessagePackageForDelete(this.announcementForm.msg_message_id),
          (del_result = await this.deleteAnnouncementMessage()),
        ]).then(() => {
          if (del_result) {
            this.deleteAnnouncementLocally(this.announcementForm.msg_message_id)
          }
          this.setLoadingStatus(false)
          this.$swal({
            icon: del_result ? 'success' : 'error',
            showCancelButton: false,
            text: del_result ? this.translations.tcDeleteSuccessful : this.translations.tcDeleteFailed,
            confirmButtonText: this.translations.tcOk || 'Ok',
          })
          this.$router.push('/get-connected/stay-informed/announcements')
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async handleCancelClick() {
      this.$router.push('/admin/announcements/manage-announcements')
    },
    async handleEditClick() {
      let level = {
        Camp: 'camp',
        Full: 'state',
        Selected: 'state',
        Committee: 'committee',
        RCommittee: 'regional-committee',
      }[this.d_recipients]
      this.$router.push({
        path: `/get-connected/stay-informed/announcements/add/add-${level}-announcement`,
      })
    },
    async removeCountries() {
      if (this.isRegionalCommitteeAnnouncement && !this.showRegionalCommittee) {
        this.clearSelectedCommitteeCountries()
      }
    },
    setTranslations() {
      this.state_country_object = {
        title: this.translations.tcEditAnnouncement,
        include: this.translations.tcIncludeNationalLeaders,
        full: this.translations.tcFullState,
        selected: this.translations.tcSelectedAreas,
        leadership: this.translations.tcStateLeadershipOnly,
      }
    },
  },
  components: {
    AnnouncementReview: AnnouncementReview,
    audienceIcon: audienceIcon,
    gBackground: gBackground,
    GeoDis: GeoDis,
    messageIcon: messageIcon,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    reviewIcon: reviewIcon,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';
// form template for add-template on AddCampAnnouncement.vue USE AddCampAnnouncement as include

.add-template {
  @include breakpoint(sm) {
    .page-header {
      text-align: center;
      h1 {
        font-size: 42px;
      }
    }
  }
  .button-group {
    .btn-primary {
      border: 1px solid #003946;
      text-transform: uppercase !important;
    }
  }
  .g-background {
    margin: 90px auto 104px;
  }
  .form-section {
    font-size: 22px;
    font-weight: bold;
    display: block;
    line-height: 44px;
    letter-spacing: 0.73px;
    text-transform: uppercase;
    color: #636363;
    margin-bottom: 10px;
  }
  .form-block {
    .form-element {
      margin-bottom: 10px;

      &.form-attachment {
        margin-bottom: 60px;
        margin-top: 45px;
        @include breakpoint(sm) {
          margin-top: 25px;
        }
      }
    }
  }
  .form-control {
    height: auto;
    font-weight: normal;
    text-transform: none;
    line-height: 25px;
    padding: 10px 20px;
    border-color: #636363;
    color: #636363;
  }
  .custom-checkbox {
    padding-left: 34px;
    line-height: 24px;
    margin-bottom: 10px;
    label {
      font-size: 16px;
      &:before {
        top: 0;
        left: -34px;
        border-radius: 0;
        width: 24px;
        height: 24px;
        border-color: #636363;
      }
      &:after {
        top: 0;
        left: -34px;
        height: 24px;
        width: 24px;
      }
    }
    .custom-control-input:checked ~ .custom-control-label::after {
      background-color: #003946;
    }
  }
  @include breakpoint(sm) {
    .form-buttons {
      .btn {
        width: 100%;
        margin-bottom: 15px !important;
        &:last-of-type {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

// 1060
// 410
// ----
// 650

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.add-state-announcement {
  .g-mb-1 {
    margin-bottom: 42px;
  }
  .g-mb-2 {
    margin-bottom: 60px;
    @include breakpoint(sm) {
      margin-bottom: 45px;
    }
  }
  .i-tooltip {
    vertical-align: super;
    svg path {
      fill: #000 !important;
    }
  }
  .view-1 {
    @include breakpoint(sm) {
      .btn-group {
        width: 100%;
      }
    }
  }
  .view-2 {
    .custom-control-inline {
      display: flex;
      margin-left: 30px;
      margin-bottom: 0;
      align-items: center;
      @include breakpoint(sm) {
        margin-left: 0;
      }
    }
    @include breakpoint(sm) {
      .btn-group {
        flex-wrap: wrap;
        width: 100%;
        .btn {
          width: 50%;
          flex: 0 1 auto;
          padding: 12px 5px;
        }
      }
      .member-type {
        flex-wrap: wrap;
        .btn-group {
          margin-bottom: 30px;
          @include breakpoint(sm) {
            // margin-bottom: ;
          }
          .btn {
            width: 33%;
          }
        }
      }
    }
  }
  .view-3 {
    input::placeholder {
      text-transform: uppercase;
      color: #636363;
      font-weight: bold;
    }
  }
  .view-4 {
    .col {
      display: block;
    }
    .col-a {
      max-width: 60%;
      flex-basis: 60%;
      @include breakpoint(sm) {
        max-width: 100%;
        flex-basis: 100%;
        margin-bottom: 30px;
      }
    }
    .col-b {
      max-width: 40%;
      flex-basis: 40%;
      @include breakpoint(sm) {
        max-width: 100%;
        flex-basis: 100%;
      }
    }
    section {
      margin-top: 20px;
      margin-bottom: 30px;
    }
    p {
      margin-bottom: 22px;
      font-size: 16px;
      line-height: 22px;
    }
    .form-attachment {
      margin-bottom: 10px;
    }
    .calendar {
      display: flex;
      align-items: center;
      svg {
        margin-right: 15px;
        path {
          fill: #636363 !important;
        }
      }
    }
  }
  .send-notifications {
    margin-bottom: 30px;
    margin-top: 20px;
  }
  .form-attachment {
    .attachment-list {
      margin-bottom: 20px;
      span {
        margin: 0 30px 0 10px;
        color: #1e68fb;
      }
      svg:last-of-type {
        width: 14px;
        height: 14px;
      }
      @include breakpoint(sm) {
        .btn {
          width: auto !important;
        }
      }
    }
  }
}
.page-navigation {
  display: flex;
  align-items: center;
  padding: 30px 80px 100px;
  @include breakpoint(sm) {
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 30px 0 30px;
  }
  .nav-item {
    position: relative;
    @include breakpoint(sm) {
      width: 100%;
    }
    .icon {
      svg {
        fill: #8c8c8c !important;
      }
    }
    &.active {
      .icon {
        background-color: $secondary-color;
        svg {
          fill: #000000 !important;
        }
      }
      .text {
        color: #000;
      }
    }
  }
  .sep {
    height: 9px;
    flex: 1 1 auto;
    background-color: #ccc;
    &.active {
      background-color: $secondary-color;
    }
    @include breakpoint(sm) {
      position: relative;
      height: 30px;
      width: 9px;
      flex: 0 0 auto;
      left: 27px;
      transform: translateX(-50%);
    }
  }
  .icon {
    position: relative;
    height: 54px;
    width: 54px;
    flex: 0 0 auto;
    background-color: #ccc;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text {
    display: block;
    position: absolute;
    width: 140px;
    top: 100%;
    left: 50%;
    margin-top: 13px;
    transform: translateX(-50%);
    color: #8c8c8c;
    font-family: $open-sans;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.6px;
    line-height: 22px;
    text-align: center;
    @include breakpoint(sm) {
      width: calc(100% - 70px);
      left: 70px;
      top: 50%;
      margin-top: 0;
      transform: translateY(-50%);
      text-align: left;
    }
  }
}
.checkbox-row {
  display: flex;
  flex-wrap: wrap;
  .checkbox-block {
    min-width: 275px;
    margin-right: 20px;
    padding: 24px 20px 35px 30px;
    border: 1px solid #e9e9e9;
    border-radius: 6px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
    @include breakpoint(sm) {
      width: 100%;
      margin-bottom: 15px;
      margin-right: 0;
    }
  }
  .bg-block-h {
    display: flex;
    margin-bottom: 15px;
    h3 {
      flex: 1 1 auto;
      margin: 0;
      color: $gray-200;
      font-family: $open-sans;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: initial;
      line-height: 22px;
      text-transform: none;
    }
    .toggle {
      height: 25px;
      width: 25px;
      flex: 0 0 auto;
      border: 2px solid $gray-200;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &:before,
      &:after {
        content: '';
        display: block;
        width: 7px;
        height: 2px;
        border-radius: 2px;
        transform: rotate(45deg);
        transform-origin: bottom;
        background-color: $gray-200;
        right: -1px;
        position: relative;
      }
      &:after {
        left: -1px;
        right: auto;
        transform: rotate(-45deg);
      }
    }
  }
  label {
    font-size: 16px;
    line-height: 22px;
  }
}
</style>
